import React, { useEffect, useState } from "react";
import styles from "./Review.module.css";
import Link from "next/link";
import { HomeReviews } from "../api";
import dynamic from "next/dynamic";
import isMobile from "is-mobile";
import { useTranslation } from "../../context/TranslationContext";
const MobileView = dynamic(() => import("./Review/MobileView"));
const DesktopView = dynamic(() => import("./Review/DesktopView"));

const Review = ({ data }) => {
  const {
    homeReviewLeftTitleSmall,
    homeReviewLeftTitle,
    homeReviewLeftText,
    reviews: reviewsText,
    allReviews,
  } = useTranslation();
  const [reviews, setReviews] = useState(false);
  const isPhone = isMobile();

  useEffect(() => {
    HomeReviews().then((result) => {
      setReviews(result.data);
    });
  }, []);

  return (
    <div className="flex w-full">
      <div className="flex-auto bg-teklif-1"></div>
      <div className="flex container flex-col lg:flex-row px-0">
        <div className="bg-teklif-gra w-full lg:w-1/3 pt-16 pb-14 text-white flex flex-col items-center lg:items-start content-center px-12 lg:pl-[1.1rem] lg:pr-0 2xl:pl-0">
          <span className="text-xl">{homeReviewLeftTitleSmall}</span>
          <span className="text-3xl text-center font-bold -mt-1">
            {homeReviewLeftTitle}
          </span>
          <p className="opacity-60 mt-3 lg:pr-32 text-sm text-center lg:text-left">
            {homeReviewLeftText}
          </p>
          <Link href={data.url}>
            <a
              title={data.title}
              className="bg-brand-blue-2 font-bold text-sm text-white py-5 px-8 mt-12"
            >
              {data.kisa_baslik}
            </a>
          </Link>
        </div>
        {reviews && (
          <div
            className={
              "lg:w-2/3 bg-white relative lg:pl-8 xl:pl-16 lg:pt-1 flex flex-col justify-between lg:pr-[1.1rem]  2xl:pr-0 " +
              styles.ReviewWrap
            }
          >
            <div className="flex flex-col bg-primary lg:bg-white pt-8 pb-12 lg:py-0 ">
              <span className="text-center lg:text-left text-white lg:text-orange font-bold text-lg lg:text-xl">
                {reviewsText}
              </span>
              <Link href={reviews.page.url}>
                <a
                  title={reviews.page.title}
                  className="mt-2.5 text-white lg:text-primary text-xs font-bold text-center lg:text-left"
                >
                  {allReviews}
                </a>
              </Link>
            </div>

            {isPhone && reviews ? <MobileView data={reviews} /> : null}
            {!isPhone && reviews ? <DesktopView data={reviews} /> : null}
          </div>
        )}
      </div>
      <div className="flex-auto"></div>
    </div>
  );
};

export default Review;
